<template>
  <b-row>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between align-items-center col-12">

      <h2>{{ $t('side_bar.reports_label') }}</h2>

      <!--   CREATE NEW REPORT   -->
      <div
        class="my-1 float-right"
      >
        <modal-button
          ripple="rgba(113, 102, 240, 0.15)"
          size="xl"
          name="modal-main"
          :modalButton="$t('reusable.modal_add_btn')"
          @btn-emit="createNewReport"
          @open-emit="clearData()"
          :backdrop-close="true"
          :modal-title="$t('report_table.add_report_title')"
          variant="success"
        >
          <template v-slot:button @click="createNewReport">{{ $t('reusable.modal_add_btn') }}</template>

          <template v-slot:modal-body>
            <div class="d-flex flex-wrap">

              <!--   SHOP ID   -->
              <b-col cols="4" class="mb-2">

                <infinity-scroll-select
                  v-model="shopId"
                  apiUrl="shops.fetchShop"
                  :label="$t('report_table.choose_shop_id')"
                />

              </b-col>

              <!--   REPORT period TYPE   -->
              <b-col cols="4" class="mb-2">

                <label>{{ $t('report_table.choose_period_type') }}</label>
                <v-select
                  v-model="periodTypes"
                  :options="periodTypesOption"
                >
                </v-select>
              </b-col>

              <!--   REPORT TYPE   -->
              <b-col cols="4" class="mb-2">

                <label>{{ $t('report_table.choose_report_type') }}</label>
                <v-select
                  v-model="reportTypes"
                  :options="reportTypesOption"
                >
                </v-select>

              </b-col>

              <!--   REPORT Number   -->
              <b-col cols="6" class="mb-2">
                <b-form-group
                  :label="$t('report_table.choose_report_number')"
                  label-for="defaultLabel"
                >
                  <b-form-input
                    v-model="reportNumber"
                    size="lg"
                    :placeholder="$t('report_table.choose_report_number')"
                  />
                </b-form-group>
              </b-col>

              <!--   date period from   -->
              <b-col cols="6" class="mb-2">
                <b-form-group
                  :label="$t('report_table.choose_date_from')"
                  label-for="defaultLabel"
                >
                  <b-form-datepicker
                    style="width: 100%; z-index: 123"
                    size="lg"
                    id="example-datepicker"
                    v-model="datePeriodFrom"
                    class="mb-2"
                    :locale="locale"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :placeholder="$t('reusable.choose_date')"
                  />
                </b-form-group>
              </b-col>

              <!--    DRAG AND DROP     -->
              <b-col cols="12">
                <VueFileAgent
                  ref="vueFileAgent"
                  :uploadUrl="uploadUrl"
                  v-model="imagesList"
                  :multiple="true"
                  :deletable="true"
                  :accept="'image/*,.zip'"
                  :maxSize="'2MB'"
                  :maxFiles="15"
                  :helpText="$t('reusable.file_upload_text')"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                >

                </VueFileAgent>
              </b-col>

              <!--    add product     -->
              <b-card
                :title="$t('product_table.add_product_title')"
                class="my-3 card_box-shadow col-12"
              >

                <span class="border-top-primary__parent">
                   <div class="d-flex flex-wrap align-items-center border-item"
                        v-for="(product,index) in products" :key="index"
                   >
                  <div class="d-flex flex-wrap col-11 px-0 pt-2">

                    <b-col cols="6" class="mb-2">
                      <infinity-scroll-select
                        v-model="product.product_id"
                        apiUrl="products.fetchProduct"
                        @input="changed"
                        :label="$t('report_table.choose_product')"
                      />
                    </b-col>

                    <b-col cols="6" class="mb-2">
                      <label>{{ $t('report_table.choose_supplier') }}</label>
                      <v-select
                        v-model="product.supplier_id"
                        :options="suppliersList"
                        label="full_name"
                      />

                    </b-col>

                    <b-col cols="6" class="mb-2">
                      <label>{{ $t('report_table.choose_variation') }}</label>
                      <v-select
                        v-model="product.variation_id"
                        :options="variationList"
                        label="name"
                      />
                    </b-col>

                    <b-col cols="6" class="mb-2">
                      <b-form-group>
                        <label>{{ $t('report_table.add_quantity') }}</label>
                        <b-form-input
                          v-model="product.quantity"
                          size="lg"
                          :placeholder="$t('report_table.add_quantity')"
                        />
                      </b-form-group>
                    </b-col>

                  </div>

                  <div class="col-1">
                    <b-button
                      @click="removeInputField(index)"
                      class="btn-danger py-0 px-1"
                      style="font-size: 30px;"
                    >
                      -
                    </b-button>
                  </div>

                </div>

                </span>

                <div class="px-1">
                  <b-button
                    class="btn-success py-0"
                    style="font-size: 30px;"
                    @click="addInputField"
                  >
                    +
                  </b-button>
                </div>

              </b-card>

            </div>
          </template>
        </modal-button>

      </div>

    </div>

    <app-collapse accordion class="w-100 px-1 mb-2">
      <app-collapse-item :title="$t('reusable.filter')">
        <b-card class="mb-0">

          <div class="filter__accordion-body">

            <b-col
              md="4"
              class="my-1 px-0"
            >
              <b-form-group
                class="mb-0"
              >
                <b-input-group size="md">
                  <b-form-input
                    id="filterInput"
                    v-model="searchFilter"
                    type="search"
                    :placeholder="$t('reusable.search_placeholder')"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!searchFilter"
                      @click="searchFilter = ''"
                    >
                      {{ $t('reusable.clear_btn') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

          </div>

        </b-card>
      </app-collapse-item>
    </app-collapse>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="searchFilter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:head(is_deleted)="data">
          <b-form-select
            size="md"
            v-model="status"
            class="w-100"
          >
            <template #first>
              <option
                selected
                :value="null"
              >
                Выберите статус
              </option>
            </template>
            <option
              v-for="(status, index) in statuses"
              :key="index"
              :value="status"
            >
              {{ status }}
            </option>
          </b-form-select>
        </template>

        <template #cell(is_deleted)="data">
          <b-badge :variant="changeVariant(data.item)">
            {{ data.item.is_deleted }}
          </b-badge>
        </template>

        <template #cell(type)="data">
          <span v-if="data.item.type == 'ds'">
            Display Share
          </span>
          <span v-else>
            Sales Report
          </span>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">{{ $t('reusable.isBusy') }}</strong>
          </div>
        </template>

        <template #cell(date_period_type)="data">
          <span v-if="data.item.date_period_type == 'week'">
            {{ $t('reusable.week') }}
          </span>
          <span v-else>
            {{ $t('reusable.month') }}
          </span>
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <b-dropdown
              no-caret
              id="table-action-dropdown"
              variant="link"
              size="md"
            >
              <template
                #button-content
                class="p-0"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="p-0"
                />
              </template>

              <!--   PREVIEW BUTTON   -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :name="`modal-preview-${data.item.id}`"
                  :backdrop-close="true"
                  :hide-footer="true"
                  :modalButton="$t('region_table.country_edit_modal_title')"
                  :id="data.item.id"
                  :modal-title="$t('reusable.preview_btn')"
                  :disableButton="!editAllow"
                  variant="flat-primary"
                  @open-emit="getReportById(data.item.id)"
                  @btn-emit="updateReport(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="EyeIcon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.preview_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="d-flex flex-wrap">

                      <!--   SHOP ID   -->
                      <b-col cols="4" class="mb-2">

                        <infinity-scroll-select
                          v-model="shopId"
                          apiUrl="shops.fetchShop"
                          :disabled="!editAllow"
                          :label="$t('report_table.choose_shop_id')"
                        />

                      </b-col>

                      <!--   REPORT TYPE   -->
                      <b-col cols="4" class="mb-2">

                        <label>{{ $t('report_table.choose_period_type') }}</label>
                        <v-select
                          v-model="periodTypes"
                          :disabled="!editAllow"
                          :options="periodTypesOption"
                        >
                        </v-select>
                      </b-col>

                      <!--   REPORT period TYPE   -->
                      <b-col cols="4" class="mb-2">

                        <label>{{ $t('report_table.choose_report_type') }}</label>
                        <v-select
                          v-model="reportTypes"
                          :disabled="!editAllow"
                          :options="reportTypesOption"
                        >
                        </v-select>

                      </b-col>

                      <!--   REPORT Number   -->
                      <b-col cols="6" class="mb-2">
                        <b-form-group
                          :label="$t('report_table.choose_report_number')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="reportNumber"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('report_table.choose_report_number')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--   date period from   -->
                      <b-col cols="6" class="mb-2">
                        <b-form-group
                          :label="$t('report_table.choose_date_from')"
                          label-for="defaultLabel"
                        >
                          <b-form-datepicker
                            style="width: 100%; z-index: 123"
                            size="lg"
                            :disabled="!editAllow"
                            id="example-datepicker"
                            v-model="datePeriodFrom"
                            class="mb-2"

                          />
                        </b-form-group>
                      </b-col>

                      <!--    DRAG AND DROP     -->
                      <b-col cols="12">
                        <VueFileAgent
                          ref="vueFileAgent"
                          :uploadUrl="uploadUrl"
                          v-model="imagesList"
                          :multiple="true"
                          :deletable="true"
                          :accept="'image/*,.zip'"
                          :maxSize="'2MB'"
                          :maxFiles="15"
                          :disabled="!editAllow"
                          :helpText="$t('reusable.file_upload_text')"
                          @select="filesSelected($event)"
                          @beforedelete="onBeforeDelete($event)"
                          @delete="fileDeleted($event)"
                        >

                        </VueFileAgent>
                      </b-col>

                      <b-col cols="6" class="mt-2">
                        <b-form-group
                          :label="$t('region_table.created_at')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="createdAt"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.created_at')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="6" class="mt-2">
                        <b-form-group
                          :label="$t('region_table.updated_at')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="updatedAt"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.updated_at')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    add product     -->
                      <b-card
                        :title="$t('product_table.additional_information')"
                        class="my-3 card_box-shadow col-12"
                      >

                <span class="border-top-primary__parent">
                   <div class="d-flex flex-wrap align-items-center border-item"
                        v-for="(product,index) in products" :key="index"
                   >
                  <div class="d-flex flex-wrap col-12 px-0 pt-2">

                    <b-col cols="6" class="mb-2">
                      <infinity-scroll-select
                        v-model="product.product_id"
                        apiUrl="products.fetchProduct"
                        @input="changed"
                        :disabled="!editAllow"
                        :label="$t('report_table.choose_product')"
                      />
                    </b-col>

                    <b-col cols="6" class="mb-2">
                      <label>{{ $t('report_table.choose_supplier') }}</label>
                      <v-select
                        v-model="product.supplier_id"
                        :options="suppliersList"
                        :disabled="!editAllow"
                        item-value="id"
                        label="full_name"
                      />

                    </b-col>

                    <b-col cols="6" class="mb-2">
                      <label>{{ $t('report_table.choose_variation') }}</label>
                      <v-select
                        :disabled="!editAllow"
                        v-model="product.variation_id"
                        :options="variationList"
                        label="name"
                      />
                    </b-col>

                    <b-col cols="6" class="mb-2">
                      <b-form-group
                        :label="$t('report_table.add_quantity')"
                        label-for="defaultLabel"
                      >
                        <b-form-input
                          v-model="product.quantity"
                          size="lg"
                          :disabled="!editAllow"
                          :placeholder="$t('quantity')"
                        />
                      </b-form-group>
                    </b-col>

                  </div>

                </div>

                </span>

                      </b-card>

                    </div>
                  </template>


                </modal-button>
              </b-dropdown-item>

              <!--  EDIT BUTTON  -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :backdrop-close="true"
                  :name="`modal-edit-${data.item.id}`"
                  :modalButton="$t('reusable.save_btn')"
                  :id="data.item.id"
                  :modal-title="$t('reusable.modal_edit_btn')"
                  variant="flat-warning"
                  @open-emit="getReportById(data.item.id)"
                  @btn-emit="updateReport(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Edit2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_edit_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="d-flex flex-wrap">

                      <!--   SHOP ID   -->
                      <b-col cols="4" class="mb-2">

                        <infinity-scroll-select
                          v-model="shopId"
                          apiUrl="shops.fetchShop"
                          :label="$t('report_table.choose_shop_id')"
                        />

                      </b-col>

                      <!--   REPORT TYPE   -->
                      <b-col cols="4" class="mb-2">

                        <label>{{ $t('report_table.choose_period_type') }}</label>
                        <v-select
                          v-model="periodTypes"
                          :options="periodTypesOption"
                        >
                        </v-select>
                      </b-col>

                      <!--   REPORT period TYPE   -->
                      <b-col cols="4" class="mb-2">

                        <label>{{ $t('report_table.choose_report_type') }}</label>
                        <v-select
                          v-model="reportTypes"
                          :options="reportTypesOption"
                        >
                        </v-select>

                      </b-col>

                      <!--   REPORT Number   -->
                      <b-col cols="6" class="mb-2">
                        <b-form-group
                          :label="$t('report_table.choose_report_number')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="reportNumber"
                            size="lg"
                            :placeholder="$t('report_number')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--   date period from   -->
                      <b-col cols="6" class="mb-2">
                        <b-form-group
                          :label="$t('report_table.choose_date_from')"
                          label-for="defaultLabel"
                        >
                          <b-form-datepicker
                            style="width: 100%; z-index: 123"
                            size="lg"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            id="example-datepicker"
                            v-model="datePeriodFrom"
                            class="mb-2"

                          />
                        </b-form-group>
                      </b-col>

                      <!--    DRAG AND DROP     -->
                      <b-col cols="12">
                        <VueFileAgent
                          ref="vueFileAgent"
                          :uploadUrl="uploadUrl"
                          v-model="imagesList"
                          :multiple="true"
                          :deletable="true"
                          :accept="'image/*,.zip'"
                          :maxSize="'2MB'"
                          :maxFiles="15"
                          :helpText="$t('reusable.file_upload_text')"
                          @select="filesSelected($event)"
                          @beforedelete="onBeforeDelete($event)"
                          @delete="fileDeleted($event)"
                        >

                        </VueFileAgent>
                      </b-col>

                      <!--    add product     -->
                      <b-card
                        :title="$t('product_table.add_product_title')"
                        class="my-3 card_box-shadow col-12"
                      >

                <span class="border-top-primary__parent">
                   <div class="d-flex flex-wrap align-items-center border-item"
                        v-for="(product,index) in products" :key="index"
                   >
                  <div class="d-flex flex-wrap col-11 px-0 pt-2">

                    <b-col cols="6" class="mb-2">
                      <infinity-scroll-select
                        v-model="product.product_id"
                        apiUrl="products.fetchProduct"
                        @input="changed"
                        :label="$t('report_table.choose_product')"
                      />
                    </b-col>

                    <b-col cols="6" class="mb-2">
                      <label>{{ $t('report_table.choose_supplier') }}</label>
                      <v-select
                        v-model="product.supplier_id"
                        :options="suppliersList"
                        item-value="id"
                        label="full_name"
                      />

                    </b-col>

                    <b-col cols="6" class="mb-2">
                      <label>{{ $t('report_table.choose_variation') }}</label>
                      <v-select
                        v-model="product.variation_id"
                        :options="variationList"
                        label="name"
                      />
                    </b-col>

                    <b-col cols="6" class="mb-2">
                      <b-form-group
                        :label="$t('report_table.add_quantity')"
                        label-for="defaultLabel"
                      >
                        <b-form-input
                          v-model="product.quantity"
                          size="lg"
                          :placeholder="$t('report_table.add_quantity')"
                        />
                      </b-form-group>
                    </b-col>

                  </div>

                  <div class="col-1">
                    <b-button
                      @click="removeInputField(index)"
                      class="btn-danger py-0 px-1"
                      style="font-size: 30px;"
                    >
                      -
                    </b-button>
                  </div>

                </div>

                </span>

                        <div class="px-1">
                          <b-button
                            class="btn-success py-0"
                            style="font-size: 30px;"
                            @click="addInputField"
                          >
                            +
                          </b-button>
                        </div>

                      </b-card>

                    </div>

                  </template>

                </modal-button>
              </b-dropdown-item>

              <!--  DELETE BUTTON  -->
              <b-dropdown-item>
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="sm"
                  :name="`modal-delete-${data.item.id}`"
                  :modalButton="$t('region_table.country_delete_modal_title')"
                  :modal-title="$t('region_table.country_delete_modal_title')"
                  variant="flat-danger"
                  :id="data.item.id"
                  @btn-emit="deleteReport"
                >
                  <template v-slot:button class="w-100">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Trash2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_delete_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="my-2">
                      {{ $t('report_table.delete_text') }}
                    </div>
                  </template>

                </modal-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </template>
      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
      cols="12"
    >
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BFormDatepicker,
  BCard,
  BSpinner,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ModalButton from '@/views/ui/modals/ModalButton.vue'
import api from '@/services/api'
import InfinityScrollSelect from '@/views/ui/infinity-scroll/InfinityScrollSelect'
import vSelect from 'vue-select'

export default {
  name: 'Report',
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormDatepicker,
    BCard,
    BSpinner,
    AppCollapse,
    AppCollapseItem,
    BFormRadioGroup,
    BFormRadio,
    vSelect,
    ModalButton,
    InfinityScrollSelect
  },

  data() {
    return {
      createdAt: '',
      updatedAt: '',
      locale: 'en-US',
      products: [{
        product_id: null,
        supplier_id: null,
        variation_id: null,
        quantity: ''
      }],
      datePeriodFrom: '',
      reportNumber: '',
      shopId: null,
      imagesList: [],
      reportTypesOption: [],
      reportTypes: {},
      periodTypesOption: [],
      periodTypes: {},
      editAllow: false,
      isDeleted: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      searchFilter: null,
      filter: [],
      filterOn: [],
      status: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      statuses: [true, false],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'number',
          label: this.$t('report_table.report_number'),
          sortable: true,
        },
        {
          key: 'type',
          label: this.$t('report_table.report_type'),
          sortable: true,
        },
        {
          key: 'date_period_type',
          label: this.$t('report_table.period_type'),
          sortable: true,
        },
        {
          key: 'shop.name',
          label: this.$t('report_table.shop_name'),
        },
        {
          key: 'creator.full_name',
          label: this.$t('shops_table.creator_fullName'),
          sortable: true,
        },
        {
          key: 'date_period_interval',
          label: this.$t('report_table.report_interval'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('region_table.created_at'),
        },
        {
          key: 'updated_at',
          label: this.$t('region_table.updated_at'),
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      variant: '',
      items: [],
      isBusy: false,
      pagination: {
        current: 1,
        total: 1,
        per_page: 5
      },
      fileRecords: [],
      uploadUrl: '',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [],
      suppliersList: [],
      variationList: [],
      itemId: null
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        this.pagination.current = query.page
        this.fetchReportList()
      },
      deep: true,
      immediate: true
    },

    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    },
    shopId: {
      handler(val) {
        if (val && Object.keys(val).length) {
          this.getSuppliersByShop()
        }
      }
    },

  },

  computed: {
    rows() {
      return this.items.length
    },
    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },

  created() {
    this.getReportTypes()
    // this.getReportTypes()
  },

  methods: {
    changed(val) {
      this.products.map((item, index) => {
        if (item.product_id && item.product_id.id === val.id) {

          this.getVariationByProduct(item.product_id.id)
        }

      })
    },

    getSuppliersByShop() {
      const data = {
        with: {
          4: 'suppliers'
        }
      }
      api.shops.fetchOneShop(this.shopId.id, data)
        .then((res) => {
          this.suppliersList = res.data.suppliers
        })
        .catch(() => {
        })
    },

    getVariationByProduct(id) {
      const data = {
        with: {
          4: 'variations.options'
        }
      }
      api.products.fetchOneProduct(id, data)
        .then((res) => {
          this.variationList = res.data.variations
        })
        .catch(() => {
        })
    },

    deleteUploadedFile: function (id, fileRecord) {
      api.reports.deleteReportFile(this.itemId, this.imagesList.indexOf(fileRecord))
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord)
    },

    filesSelected: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    },

    onBeforeDelete: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
        this.deleteUploadedFile(fileRecord)
      } else {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },

    async fileDeleted(fileRecord) {
      const deleteFileRecord = this.imagesList.findIndex(image => image.url === fileRecord.url)
      await api.reports.deleteReportFile(this.itemId, deleteFileRecord)
      // Using the default uploader. You may use another uploader instead.
      await this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord)
      let i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      }
    },

    replaceRouter(query) {
      this.$router.replace({ query })
        .catch(() => {
        })
    },

    async fetchReportList() {
      const data = {
        with: {
          0: 'creator',
          1: 'shop'
        },
        page: this.$route.query.page
      }
      this.isBusy = true
      await api.reports.fetchReport(data)
        .then(res => {
          this.items = res.data.data
          this.pagination.per_page = res.data.meta.per_page
          this.pagination.total = res.data.meta.total
          this.pagination.current = res.data.meta.current_page
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async getReportById(id) {
      this.clearData()
      const data = {
        with: {
          0: 'shop',
          1: 'products.product',
          2: 'products.variation.options',
          3: 'products.supplier'
        }
      }
      this.itemId = id
      this.editAllow = false
      await api.reports.fetchOneReport(id, data)
        .then(res => {
          this.createdAt = res.data.created_at
          this.updatedAt = res.data.updated_at
          this.shopId = res.data.shop
          this.reportTypesOption.map(item => {
            if (item.value === res.data.type) {
              this.reportTypes = item
            }
          })
          this.periodTypesOption.map(item => {
            if (item.value === res.data.date_period_type) {
              this.periodTypes = item
            }
          })
          this.reportNumber = res.data.number

          this.datePeriodFrom = res.data.date_period_from
          const [day, month, year] = this.datePeriodFrom.split('.')
          this.datePeriodFrom = [year, month, day].join('-')

          for (let i = 0; i < res.data.images_list.length; i++) {
            const image = res.data.images_list[i].original
            this.imagesList = [...this.imagesList, {
              name: 'image.jpg',
              size: 0,
              type: 'image/jpg',
              url: image,
              src: image
            }]
          }

          res.data.products.map((item, index) => {
            this.products[index] = {
              ...item,
              product_id: item.product,
              supplier_id: item.supplier,
              variation_id: item.variation
            }
          })

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    createNewReport() {
      const formData = new FormData()

      const [year, month, day] = this.datePeriodFrom.split('-')
      this.datePeriodFrom = [day, month, year].join('.')

      for (let i = 0; i < this.products.length; i++) {
        for (let [key, value] of Object.entries(this.products[i])) {
          if (key === 'quantity') {
            formData.append(`products[${i}][${key}]`, value)
          } else {
            formData.append(`products[${i}][${key}]`, [value.id])
          }
        }
      }

      for (let i = 0; i < this.imagesList.length; i++) {
        formData.append('images_list[' + i + ']', this.imagesList[i].file)
      }

      formData.append('date_period_type', this.periodTypes.value)
      formData.append('number', this.reportNumber)
      formData.append('date_period_from', this.datePeriodFrom)
      formData.append('shop_id', this.shopId.id)
      formData.append('type', this.reportTypes.value)

      api.reports.createReport(formData)
        .then(res => {
          console.log(res)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchReportList()
        })
    },

    clearData() {
      this.shopId = []
      this.periodTypes = []
      this.reportTypes = []
      this.reportNumber = ''
      this.datePeriodFrom = null
      this.imagesList = []
      this.products = [{
        product_id: null,
        supplier_id: null,
        variation_id: null,
        quantity: ''
      }]
    },

    getReportTypes() {
      api.enums.fetchReportTypes()
        .then(res => {
          if (!(this.reportTypesOption.length || this.periodTypesOption.length)) {
            for (let [key, value] of Object.entries(res.data.type)) {
              this.reportTypesOption.push({
                'value': key, ...value
              })
            }
            for (let [key, value] of Object.entries(res.data.date_period_type)) {
              this.periodTypesOption.push({
                'value': key, ...value
              })
            }
          }

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.clearData()
        })
    },

    deleteReport(id) {
      api.reports.deleteReport(id)
        .then(res => {
          // console.log(res)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchReportList()
        })
    },

    updateReport(id, data) {
      const formData = new FormData()

      const [year, month, day] = this.datePeriodFrom.split('-')
      this.datePeriodFrom = [day, month, year].join('.')

      for (let i = 0; i < this.products.length; i++) {
        for (let [key, value] of Object.entries(this.products[i])) {
          if (key === 'quantity') {
            formData.append(`products[${i}][${key}]`, [value])
          } else if (key === 'variation_id' || key === 'supplier_id' || key === 'product_id') {
            formData.append(`products[${i}][${key}]`, [value.id])
          } else if (key === 'id') {
            formData.append(`products[${i}][${key}]`, this.products[i].product_id.id)
          }
        }
      }

      for (let i = 0; i < this.imagesList.length; i++) {
        if (this.imagesList[i].file && this.imagesList[i].file.webkitRelativePath == '') {
          formData.append('images_list[' + i + ']', this.imagesList[i].file)
        }
      }

      formData.append('date_period_type', this.periodTypes.value)
      formData.append('number', this.reportNumber)
      formData.append('date_period_from', this.datePeriodFrom)
      formData.append('shop_id', this.shopId.id)
      formData.append('type', this.reportTypes.value)
      api.reports.updateReport(id, formData)
        .then(res => {

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchReportList()
        })
    },

    addInputField() {
      this.products = [...this.products, {
        product_id: {},
        supplier_id: {},
        variation_id: {},
        quantity: ''
      }]

    },

    removeInputField(value) {
      this.products.splice(value, 1)
    },

    // checkStatus(value) {
    //   if (value) return true
    //   return false
    // },

    changeVariant(value) {
      if (value.is_deleted) return 'danger'
      return 'success'
    },

    checkLocales(value) {
      if (localStorage.lang === 'ru') {
        return value.ru
      }
      return value.uz
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">

[dir] .dropdown-item {
  padding: 0;
}

[dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::before {
  left: 0;
  top: 0;
  //position: static;
  margin-right: 12px;
}

[dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::after {
  left: 0;
  top: 0;
  margin-right: 12px;
}

.custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
  display: flex;
  align-items: center;
}

::v-deep .vs__dropdown-menu {
  max-height: 100px !important;
}

.list-group-wrapper {
  position: relative;
}

.list-group {
  overflow: auto;
  height: 100px;
  border: 2px solid #dce4ec;
  border-radius: 5px;
}

.list-group-item {
  margin-top: 1px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid #dce4ec;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

.card_box-shadow {
  box-shadow: 0 0 10px #1d172e !important;
}

.border-top-primary__parent {

  .border-item {
    border-bottom: 2px solid #1d172e;

  }

  .border-item:last-child {
    border-bottom: none !important;

  }

}


</style>
